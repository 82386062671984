<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" style="margin: 20px 0;">
    <el-breadcrumb-item :to="{ path: '/project' }">项目列表</el-breadcrumb-item>
    <el-breadcrumb-item>项目模块</el-breadcrumb-item>
  </el-breadcrumb>
  <el-button type="primary" @click="add()">添加模块</el-button>
  <el-table
      :data="data.tableData"
      border
      style="width: 100%">
    <el-table-column
        prop="key"
        label="key">
    </el-table-column>
    <el-table-column
        prop="name"
        label="模块名称">
    </el-table-column>
    <el-table-column
        prop="type"
        label="类型"
        :formatter="formatter">
    </el-table-column>
    <el-table-column
        label="模块预览图">
      <template #default="scope">
        <el-image
            style=" height: 100px"
            :src="this.utils.api.file.cdnImage(scope.row.image)+'@h=100'"
            :preview-src-list="[this.utils.api.file.cdnImage(scope.row.image)]"
            fit="scale-down"></el-image>
      </template>
    </el-table-column>
    <el-table-column
        prop="create_user_name"
        label="创建人">
    </el-table-column>
    <el-table-column
        prop="password"
        label="访问密码">
    </el-table-column>
    <el-table-column
        fixed="right"
        label="操作"
        width="200">
      <template #default="scope">
        <el-button type="text" @click="openModule(scope.row.path)" size="small">分享</el-button>
        <el-button type="text" @click="resetPassword(scope.row.id)" size="small">重置密码</el-button>
        <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      background
      layout="prev, pager, next"
      @current-change="currentChange"
      :current-page="data.pagination.currentPage"
      :page-size="data.pagination.pagerSize"
      :total="data.pagination.total">
  </el-pagination>
  <el-dialog v-model="data.dialogVisible.moduleAdd" title="添加模块">
    <el-form ref="elForm" :model="data.formData" :rules="data.rules" size="medium" label-width="100px">
      <el-form-item label="模块索引" prop="key">
        <el-input v-model="data.formData.key" placeholder="请输入模块索引" :maxlength="32" show-word-limit clearable
                  :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item label="模块名称" prop="name">
        <el-input v-model="data.formData.name" placeholder="请输入名称项目项目名称" :maxlength="64" show-word-limit clearable
                  :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item label="模块类型" prop="type" required>
        <el-radio-group v-model="data.formData.type" @change="typeChange" size="mini">
          <el-radio-button label="1">原型</el-radio-button>
          <el-radio-button label="2">pdf</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="预览图" prop="image" required>
        <el-upload ref="image" class="avatar-uploader" :show-file-list="false" :action="data.imageAction" :headers="data.upHeaders"
                   :before-upload="imageBeforeUpload" :on-success="handleImageSuccess" accept="image/*" name="image">
          <img v-if="data.formData.image" :src="this.utils.api.file.cdnImage(data.formData.image)+'@w=178&h=178'" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="data.fileLable" prop="axure" required>
        <el-upload ref="axure" class="upload-demo" drag :action="data.axureAction" :headers="data.upHeaders"
                   :before-upload="axureBeforeUpload" :on-success="handleAxureSuccess" limit="2" :accept="data.fileType">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <template #tip>
            <div class="el-upload__tip">
              只能上传 {{ data.fileType }} 文件，且不超过 2000M
            </div>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="handelConfirm">确定</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="data.dialogVisible.moduleEdit" title="编辑模块">
    <el-form ref="editForm" :model="data.editData" :rules="data.rules" size="medium" label-width="100px">
      <el-form-item label="模块索引" prop="key">
        <el-input v-model="data.editData.key" placeholder="请输入模块索引" :maxlength="32" show-word-limit clearable
                  :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="data.editData.name" placeholder="请输入名称项目项目名称" :maxlength="64" show-word-limit clearable
                  :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item label="模块类型" prop="type" required>
        <el-radio-group v-model="data.editData.type" @change="typeChange" size="mini">
          <el-radio-button label="1">原型</el-radio-button>
          <el-radio-button label="2">pdf</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="预览图" prop="image" required>
        <el-upload ref="image" class="avatar-uploader" :show-file-list="false" :action="data.imageAction" :headers="data.upHeaders"
                   :before-upload="imageBeforeUpload" :on-success="handleImageEditSuccess" accept="image/*" name="image">
          <img v-if="data.editData.image" :src="this.utils.api.file.cdnImage(data.editData.image)+'@w=178&h=178'" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item :label="data.fileLable" prop="axure">
        <el-upload ref="axure" class="upload-demo" drag :action="data.axureAction" :headers="data.upHeaders"
                   :before-upload="axureBeforeUpload" :on-success="handleAxureEditSuccess" limit="2" :accept="data.fileType">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <template #tip>
            <div class="el-upload__tip">
              只能上传 {{ data.fileType }} 文件，且不超过 2000M
            </div>
          </template>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="data.dialogVisible.moduleEdit = false">取消</el-button>
      <el-button type="primary" @click="editConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {getCurrentInstance, reactive} from "vue";
import {api} from "@/api";
import {router} from "@/router";
import {utils} from "@/utils";

export default {
  name: "module",
  setup() {
    const ctx = getCurrentInstance()
    const data = reactive({
      projectId: Number(router.currentRoute.value.params.projectId),
      tableData: [],
      pagination: {
        total: 0,
        currentPage:1,
        pagerSize:15,
      },
      dialogVisible: {
        moduleAdd: false,
        moduleEdit: false
      },
      formData: {
        key: undefined,
        name: undefined,
        image: undefined,
        axure: undefined,
        type: "1",
      },
      editData: {
        projectModuleId: undefined,
        key: undefined,
        name: undefined,
        image: undefined,
        axure: undefined,
        type: undefined,
      },
      rules: {
        key: [{
          required: true,
          message: '请输入模块索引',
          trigger: 'blur'
        }, {
          pattern: /^[A-Za-z0-9]*$/,
          message: '只能输入数字字母',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入名称项目项目名称',
          trigger: 'blur'
        }],
      },
      imageAction: api.file.imagePath,
      axureAction: api.file.filePath,
      fileType: ".zip",
      fileLable: "原型图",
      upHeaders: api.file.upHeaders()
    })
    const methods = {
      query: () => {
        api.project.moduleLists({
          projectId: data.projectId,
          limit: data.pagination.pagerSize,
          page: data.pagination.currentPage
        }).then(res => {
          data.tableData = []
          data.pagination.total = res.data.total
          res.data.list.forEach(item => {
            data.tableData.push({
              id: item.id,
              key: item.key,
              name: item.name,
              create_user_name: item.create_user_name,
              path: item.path,
              type: item.type,
              image: item.image,
              password: item.password,
              created_at: item.created_at,
              updated_at: item.updated_at,
            })
          })
        })
      },
      currentChange: (page) => {
        data.pagination.currentPage = page
        methods.query()
      },
      openModule: (path) => {
        window.open(api.common.axurePath + path, '_blank');
      },
      add: () => {
        data.dialogVisible.moduleAdd = true
      },
      edit: (row) => {
        data.editData.projectModuleId = row.id
        data.editData.name = row.name
        data.editData.key = row.key
        data.editData.image = row.image
        data.editData.axure = ''
        data.editData.type = row.type === 2 ? "2" : "1"
        data.dialogVisible.moduleEdit = true
        methods.typeChange(data.editData.type)
      },
      editConfirm: () => {
        ctx.refs['editForm'].validate(valid => {
          if (valid) {
            api.project.moduleEdit({
              projectModuleId: Number(data.editData.projectModuleId),
              name: data.editData.name,
              key: data.editData.key,
              image: data.editData.image,
              path: data.editData.axure,
            }).then(res => {
              if (res.code === 0) {
                utils.message.success("编辑成功")
                methods.query()
                data.dialogVisible.moduleEdit = false
              }else{
                utils.message.error(res.msg)
              }
            })
          }
        })
      },
      close:() => {
        data.dialogVisible.moduleAdd = false
        ctx.refs['elForm'].resetFields()
      },
      handelConfirm: () => {
        ctx.refs['elForm'].validate(valid => {
          if (valid) {
            api.project.moduleAdd({
              projectId: data.projectId,
              key: data.formData.key,
              name: data.formData.name,
              image: data.formData.image,
              path: data.formData.axure,
              type: Number(data.formData.type),

            }).then(res => {
              console.log(res)
              if (res.code === 0) {
                utils.message.success("添加成功")
                methods.close()
                methods.query()
              }else{
                utils.message.error(res.msg)
              }
            })
          }
        })
      },
      imageBeforeUpload: (file) => {
        let isRightSize = file.size / 1024 / 1024 < 10
        if (!isRightSize) {
          utils.message.error('文件大小超过 10MB')
        }
        let isAccept = new RegExp('image/*').test(file.type)
        if (!isAccept) {
          utils.message.error('应该选择image/*类型的文件')
        }
        return isRightSize && isAccept
      },
      handleImageSuccess: (res) => {
        if (res.code === 0) {
          data.formData.image = res.data.path
        } else {
          utils.message.error(res.msg)
        }
      },
      handleImageEditSuccess: (res) => {
        if (res.code === 0) {
          data.editData.image = res.data.path
        } else {
          utils.message.error(res.msg)
        }
      },
      axureBeforeUpload:(file) => {
        let isRightSize = file.size / 1024 / 1024 < 2000
        if (!isRightSize) {
          utils.message.error('文件大小超过 2000MB')
        }
        let isAccept = new RegExp(data.fileType).test(file.type)
        if (!isAccept) {
          utils.message.error('应该选择'+data.fileType+'类型的文件')
        }
        return isRightSize && isAccept
      },
      handleAxureSuccess: (res, file, fileList) => {
        if (res.code === 0) {
          data.formData.axure = res.data.path
          if (fileList.length > 1) {
            fileList.splice(0, 1)
          }
        } else {
          fileList.splice(0, 1)
          utils.message.error('上传失败')
        }
      },
      handleAxureEditSuccess: (res, file, fileList) => {
        if (res.code === 0) {
          data.editData.axure = res.data.path
          if (fileList.length > 1) {
            fileList.splice(0, 1)
          }
        } else {
          fileList.splice(0, 1)
          utils.message.error('上传失败')
        }
      },
      resetPassword: (projectModuleId) => {
        api.project.moduleResetPassword(projectModuleId).then(res => {
          if (res.code === 0) {
            utils.message.success("重置成功")
            methods.query()
          } else {
            utils.message.error(res.msg)
          }
        })
      },
      typeChange: (val) => {
        switch (val) {
          case "2":
            data.fileType = ".pdf"
            data.fileLable = "PDF"
            break
          default:
            data.fileType = ".zip"
            data.fileLable = "原型图"
            break
        }
        if (ctx.refs.axure !== undefined) {
          ctx.refs.axure.clearFiles()
        }
        data.editData.axure = undefined
        data.formData.axure = undefined
      },
      formatter: (row, column) => {
        switch (column.property) {
          case "type":
            switch (row.type) {
              case 1:
                return "原型"
              case 2:
                return "PDF"
              default:
                return "原型"
            }
        }
        return row.type
      }
    }
    methods.query()
    return {
      data,
      ...methods
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
